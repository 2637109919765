// project import
import axios from 'utils/axios';
import { dispatch } from 'store';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// types
import { User, UserProps } from 'types/user';

const initialState: UserProps = {
  users: [],
  user: null,
  error: null
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUsers(state, action) {
      state.users = action.payload.map((jsonObj: any) => {
        return new User({ ...jsonObj });
      });
    },
    getUser(state, action) {
      state.user = new User({ ...action.payload });
    },
    hasError(state, action) {
      state.error = action.payload.error;
    }
  }
});

export default user.reducer;

export function getUsers() {
  return async () => {
    try {
      const response = await axios.get('/users');
      dispatch(user.actions.getUsers(response.data.data.items));
    } catch (error) {
      dispatch(user.actions.hasError(error));
    }
  };
}

export function getUser(userId: any) {
  return async () => {
    try {
      const response = await axios.get(`/users/${userId}`);
      dispatch(user.actions.getUser(response.data.data.items));
    } catch (error) {
      dispatch(user.actions.hasError(error));
    }
  };
}
