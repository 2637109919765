import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types/auth';

const RoleGuard = ({ children }: GuardProps) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user || user.role !== 'ADMIN') {
      navigate('/maintenance/404', {
        state: {
          from: location.pathname
        },
        replace: true
      });
    }
  }, [user, navigate, location]);

  return children;
};

export default RoleGuard;
