// project import
import axios from 'utils/axios';
import { dispatch } from 'store';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// types
import { Event, EventProps } from 'types/event';
import { EventUser } from 'types/user';
import { Invoice } from 'types/invoice';

const initialState: EventProps = {
  events: [],
  event: null,
  error: null,
  users: [],
  invoices: [],
  usersEventId: null
};

const event = createSlice({
  name: 'event',
  initialState,
  reducers: {
    getEvents(state, action) {
      state.events = action.payload.map((jsonObj: any) => {
        return new Event({ ...jsonObj });
      });
    },
    getEvent(state, action) {
      state.event = new Event({ ...action.payload });
    },
    setEventUsers(state, action) {
      state.users = action.payload.map((jsonObj: any) => {
        return new EventUser({ ...jsonObj });
      });
    },
    setEventInvoices(state, action) {
      state.invoices = action.payload.map((jsonObj: any) => {
        return new Invoice({ ...jsonObj });
      });
    },
    hasError(state, action) {
      state.error = action.payload.error;
    }
  }
});

export default event.reducer;

export function getEventList() {
  return async () => {
    try {
      const response = await axios.get('/events?sort=["-Event Start"]');
      dispatch(event.actions.getEvents(response.data.data.items));
    } catch (error) {
      dispatch(event.actions.hasError(error));
    }
  };
}

export function getEvent(eventId: any) {
  return async () => {
    try {
      const response = await axios.get(`/events/${eventId}`);
      dispatch(event.actions.getEvent(response.data.data.item));
    } catch (error) {
      dispatch(event.actions.hasError(error));
    }
  };
}

// export function getEventUsers(eventId: any) {
//   return async () => {
//     try {
//       const response = await axios.get(`/events/${eventId}/users`);
//       dispatch(event.actions.getEventUsers(response.data.data.items));
//     } catch (error) {
//       dispatch(event.actions.hasError(error));
//     }
//   };
// }

export function setEventUsers(users: any) {
  dispatch(event.actions.setEventUsers(users));
}

export function setEventInvoices(invoices: any) {
  dispatch(event.actions.setEventInvoices(invoices));
}
