// project import
import menu from './menu';
import admin from './admin';
import settings from './settings';

// types
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const userItems: { items: NavItemType[] } = {
  items: [menu, settings]
};

const adminItems: { items: NavItemType[] } = {
  items: [menu, admin, settings]
};

export { userItems, adminItems };
