// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { ThunderboltOutlined, FileTextOutlined } from '@ant-design/icons';

// type
import { NavItemType } from '../types/menu';

// icons
const icons = {
  ThunderboltOutlined,
  FileTextOutlined
};

const menu: NavItemType = {
  id: 'menu',
  type: 'group',
  children: [
    {
      id: 'events',
      title: <FormattedMessage id="events" />,
      type: 'item',
      url: '/events',
      icon: icons.ThunderboltOutlined
    },
    {
      id: 'invoices',
      title: <FormattedMessage id="invoices" />,
      type: 'item',
      url: '/invoices',
      icon: icons.FileTextOutlined
    }
  ]
};

export default menu;
