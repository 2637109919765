import { IEventUser } from './user';
import { Invoice } from './invoice';

export enum EventCategory {
  DAT_STORM_WORK = 'DAT Storm Work',
  LINE_CREW_STORM_WORK = 'Line Crew Storm Work',
  SAFETY_OVERSIGHT_STORM_WORK = 'Safety Oversight Storm Work',
  WD_STORM_WORK = 'WD Storm Work',
  BASE_CAMP_STORM_WORK = 'Base Camp Storm Work',
  CONFERENCE = 'Conference',
  RODEO = 'Rodeo'
}

export interface IEvent {
  id: string;
  name: string;
  clients: string[];
  categories: EventCategory[];
  workStates: string[];
  start: Date;
  end?: Date | undefined;
  complete: boolean;
  notes: string;
}

export type EventCardProps = {
  event: Event;
};

export interface EventProps {
  events: Event[];
  event: Event | null;
  error: object | string | null;
  users: IEventUser[];
  invoices: Invoice[];
  usersEventId: string | null;
}

export class Event implements IEvent {
  id: string;
  name: string;
  clients: string[];
  categories: EventCategory[];
  workStates: string[];
  start: Date;
  end: Date | undefined;
  complete: boolean;
  notes: string;

  constructor({ id, name, clients, categories, workStates, start, end, complete, notes }: IEvent) {
    this.id = id;
    this.name = name;
    this.clients = clients;
    this.categories = categories;
    this.workStates = workStates;
    this.start = new Date(start);
    this.end = end ? new Date(end) : end;
    this.complete = complete;
    this.notes = notes;
  }
}
