// ==============================|| INVOICE - SLICE ||============================== //

import { UserProfile } from './auth';
import { Event } from './event';
import { Client } from './client';

export interface Items {
  id: string | number;
  name: string;
  description: string;
  qty: number;
  price: string | number;
}

export interface InvoiceProps {
  invoices: Invoice[];
  invoice: Invoice | null;
  error: object | string | null;
  alertPopup: boolean;
}

export enum Position {
  MANAGER = 'Manager',
  LEAD = 'Lead',
  ASSESSOR = 'Assessor',
  DRIVER = 'Driver',
  WIRE_DOWN_LEAD = 'Wire Down Lead',
  WIRE_DOWN = 'Wire Down',
  SR_ADMIN = 'SR Admin',
  ADMIN = 'Admin',
  LINE_SAFETY = 'Line Safety',
  DAT_SAFETY = 'DAT Safety',
  IT_LEAD = 'IT Lead',
  IT_SUPPORT = 'IT Support',
  SUPPORT = 'Support',
  BASE_CAMP_MANAGER = 'Base Camp Manager',
  BASE_CAMP_LEAD = 'Base Camp Lead',
  BASE_CAMP_SUPPORT = 'Base Camp Support',
  CREW_COORDINATOR = 'Crew Coordinator',
  HOME_ADMIN_LOGISTICS_SUPPORT = 'Home Admin/Logistics Support'
}

export enum DivisionShortCode {
  DAT = 'DAT',
  WIRE_DOWN = 'WD',
  LINE_CREW = 'LC',
  SAFETY_OVERSIGHT = 'SO',
  DRONE = 'DR',
  BASE_CAMP = 'BC',
  TREE_CREW = 'TC'
}

export enum Division {
  DAT = 'DAT - Damage Assessment',
  WIRE_DOWN = 'WD - Wire Down',
  LINE_CREW = 'LC - Line Crew',
  SAFETY_OVERSIGHT = 'SO - Safety Oversight',
  DRONE = 'DR - Drone',
  BASE_CAMP = 'BC - Base Camp',
  TREE_CREW = 'TC - Tree Crew'
}

export enum InvoiceState {
  DRAFT = 'Draft',
  SUBMITTED = 'Submitted',
  REVIEW = 'Review',
  ACTION_REQUIRED = 'Action Required',
  APPROVAL_REQUIRED = 'Approval Required',
  DISPUTED = 'Disputed',
  CONTRACTOR_APPROVED = 'Contractor Approved',
  APPROVED = 'Approved'
}

export const ADMIN_INVOICE_STATE_TRANSITIONS: Record<InvoiceState, InvoiceState[]> = {
  [InvoiceState.DRAFT]: [InvoiceState.SUBMITTED],
  [InvoiceState.SUBMITTED]: [InvoiceState.REVIEW, InvoiceState.ACTION_REQUIRED, InvoiceState.APPROVAL_REQUIRED, InvoiceState.APPROVED],
  [InvoiceState.REVIEW]: [InvoiceState.ACTION_REQUIRED, InvoiceState.APPROVAL_REQUIRED, InvoiceState.APPROVED],
  [InvoiceState.ACTION_REQUIRED]: [InvoiceState.SUBMITTED, InvoiceState.DISPUTED],
  [InvoiceState.APPROVAL_REQUIRED]: [InvoiceState.DISPUTED, InvoiceState.APPROVED],
  [InvoiceState.DISPUTED]: [InvoiceState.REVIEW, InvoiceState.ACTION_REQUIRED, InvoiceState.APPROVAL_REQUIRED],
  [InvoiceState.CONTRACTOR_APPROVED]: [InvoiceState.APPROVED],
  [InvoiceState.APPROVED]: []
};

export const USER_INVOICE_STATE_TRANSITIONS: Record<InvoiceState, InvoiceState[]> = {
  [InvoiceState.DRAFT]: [InvoiceState.SUBMITTED],
  [InvoiceState.SUBMITTED]: [],
  [InvoiceState.REVIEW]: [],
  [InvoiceState.ACTION_REQUIRED]: [InvoiceState.SUBMITTED, InvoiceState.DISPUTED],
  [InvoiceState.APPROVAL_REQUIRED]: [InvoiceState.DISPUTED, InvoiceState.CONTRACTOR_APPROVED],
  [InvoiceState.DISPUTED]: [],
  [InvoiceState.CONTRACTOR_APPROVED]: [],
  [InvoiceState.APPROVED]: []
};

export enum InvoiceLineItemType {
  LABOR = 'Labor',
  EXPENSE = 'Expense',
  UNDEFINED = 'undefined'
}

export const INVOICE_LINE_ITEM_TYPE_ORDER: InvoiceLineItemType[] = [InvoiceLineItemType.LABOR, InvoiceLineItemType.EXPENSE];
export enum InvoiceLineItemSubtype {
  REGULAR = 'Regular Rate',
  SPECIAL_RATE_1 = 'Special Rate 1',
  SPECIAL_RATE_2 = 'Special Rate 2',
  MEALS_PER_DIEM = 'Meals - Per Diem',
  MEALS_RECEIPTS = 'Meals - Receipts',
  LODGING = 'Lodging',
  MILEAGE = 'Mileage',
  PERSONAL_VEHICLE = 'Personal Vehicle',
  FUEL = 'Fuel',
  TOLLS = 'Tolls',
  PARKING = 'Parking',
  LUGGAGE = 'Luggage',
  TRANSPORTATION = 'Transportation',
  FLIGHTS = 'Flights',
  RENTAL = 'Rental',
  MISC = 'Misc',
  UNDEFINED = 'undefined'
}
export const INVOICE_LINE_ITEM_SUBTYPE_ORDER: InvoiceLineItemSubtype[] = [
  InvoiceLineItemSubtype.REGULAR,
  InvoiceLineItemSubtype.SPECIAL_RATE_1,
  InvoiceLineItemSubtype.SPECIAL_RATE_2,
  InvoiceLineItemSubtype.MEALS_PER_DIEM,
  InvoiceLineItemSubtype.MEALS_RECEIPTS,
  InvoiceLineItemSubtype.LODGING,
  InvoiceLineItemSubtype.MILEAGE,
  InvoiceLineItemSubtype.PERSONAL_VEHICLE,
  InvoiceLineItemSubtype.FUEL,
  InvoiceLineItemSubtype.TOLLS,
  InvoiceLineItemSubtype.PARKING,
  InvoiceLineItemSubtype.LUGGAGE,
  InvoiceLineItemSubtype.TRANSPORTATION,
  InvoiceLineItemSubtype.FLIGHTS,
  InvoiceLineItemSubtype.RENTAL,
  InvoiceLineItemSubtype.MISC
];

export const TYPE_TO_SUBTYPE_MAP: Record<InvoiceLineItemType, InvoiceLineItemSubtype[]> = {
  [InvoiceLineItemType.LABOR]: [
    InvoiceLineItemSubtype.REGULAR,
    InvoiceLineItemSubtype.SPECIAL_RATE_1,
    InvoiceLineItemSubtype.SPECIAL_RATE_2
  ],
  [InvoiceLineItemType.EXPENSE]: [
    InvoiceLineItemSubtype.MEALS_PER_DIEM,
    InvoiceLineItemSubtype.MEALS_RECEIPTS,
    InvoiceLineItemSubtype.LODGING,
    InvoiceLineItemSubtype.MILEAGE,
    InvoiceLineItemSubtype.PERSONAL_VEHICLE,
    InvoiceLineItemSubtype.FUEL,
    InvoiceLineItemSubtype.TOLLS,
    InvoiceLineItemSubtype.PARKING,
    InvoiceLineItemSubtype.LUGGAGE,
    InvoiceLineItemSubtype.TRANSPORTATION,
    InvoiceLineItemSubtype.FLIGHTS,
    InvoiceLineItemSubtype.RENTAL,
    InvoiceLineItemSubtype.MISC
  ],
  [InvoiceLineItemType.UNDEFINED]: [InvoiceLineItemSubtype.UNDEFINED]
};

type SubtypeToTypeMap = {
  [key in InvoiceLineItemSubtype]: InvoiceLineItemType;
};

export const SUBTYPE_TO_TYPE_MAP: SubtypeToTypeMap = {
  [InvoiceLineItemSubtype.REGULAR]: InvoiceLineItemType.LABOR,
  [InvoiceLineItemSubtype.SPECIAL_RATE_1]: InvoiceLineItemType.LABOR,
  [InvoiceLineItemSubtype.SPECIAL_RATE_2]: InvoiceLineItemType.LABOR,
  [InvoiceLineItemSubtype.MEALS_PER_DIEM]: InvoiceLineItemType.EXPENSE,
  [InvoiceLineItemSubtype.MEALS_RECEIPTS]: InvoiceLineItemType.EXPENSE,
  [InvoiceLineItemSubtype.LODGING]: InvoiceLineItemType.EXPENSE,
  [InvoiceLineItemSubtype.MILEAGE]: InvoiceLineItemType.EXPENSE,
  [InvoiceLineItemSubtype.PERSONAL_VEHICLE]: InvoiceLineItemType.EXPENSE,
  [InvoiceLineItemSubtype.FUEL]: InvoiceLineItemType.EXPENSE,
  [InvoiceLineItemSubtype.TOLLS]: InvoiceLineItemType.EXPENSE,
  [InvoiceLineItemSubtype.PARKING]: InvoiceLineItemType.EXPENSE,
  [InvoiceLineItemSubtype.LUGGAGE]: InvoiceLineItemType.EXPENSE,
  [InvoiceLineItemSubtype.TRANSPORTATION]: InvoiceLineItemType.EXPENSE,
  [InvoiceLineItemSubtype.FLIGHTS]: InvoiceLineItemType.EXPENSE,
  [InvoiceLineItemSubtype.RENTAL]: InvoiceLineItemType.EXPENSE,
  [InvoiceLineItemSubtype.MISC]: InvoiceLineItemType.EXPENSE,
  [InvoiceLineItemSubtype.UNDEFINED]: InvoiceLineItemType.UNDEFINED
};

export const SUBTYPES_WITH_VARIABLE_RATES: InvoiceLineItemSubtype[] = [
  InvoiceLineItemSubtype.REGULAR,
  InvoiceLineItemSubtype.SPECIAL_RATE_1,
  InvoiceLineItemSubtype.SPECIAL_RATE_2,
  InvoiceLineItemSubtype.MEALS_PER_DIEM
];

export const MILEAGE_RATE = 0.69;
export const PERSONAL_VEHICLE_RATE = 12.0;

export interface IInvoiceLineItem {
  id: string;
  lineItemId: string;
  version: number;
  type: InvoiceLineItemType;
  subtype: InvoiceLineItemSubtype;
  qty: number;
  rate: number;
  desc?: string;
}

export class InvoiceLineItem implements IInvoiceLineItem {
  id: string;
  lineItemId: string;
  qty: number;
  rate: number;
  subtype: InvoiceLineItemSubtype;
  type: InvoiceLineItemType;
  version: number;
  desc: string | undefined;

  constructor({ id, lineItemId, qty, rate, subtype, type, version, desc }: IInvoiceLineItem) {
    this.id = id;
    this.lineItemId = lineItemId;
    this.qty = qty;
    this.rate = rate;
    this.subtype = subtype;
    this.type = type;
    this.version = version;
    this.desc = desc;
  }

  total(): number {
    return this.qty * this.rate;
  }
}

export interface IInvoiceNote {
  id: string;
  note: string;
  author: UserProfile;
  isInternal: boolean;
}

export class InvoiceNote implements IInvoiceNote {
  id: string;
  note: string;
  author: UserProfile;
  isInternal: boolean;

  constructor({ id, note, author, isInternal}: IInvoiceNote) {
    this.id = id;
    this.note = note;
    this.author = author;
    this.isInternal = isInternal;
  }
}

export interface IInvoice {
  id: string;
  compoundId: string;
  invoiceId: string;
  version: number;
  state: InvoiceState;
  contractor: UserProfile;
  event: Event;
  client: Client;
  division: Division;
  position: Position;
  lead: string;
  lineItems: IInvoiceLineItem[];
  submissionDate: Date | string;
  notes: IInvoiceNote[];
}

export class Invoice implements IInvoice {
  id: string;
  compoundId: string;
  invoiceId: string;
  version: number;
  state: InvoiceState;
  contractor: UserProfile;
  event: Event;
  client: Client;
  division: Division;
  position: Position;
  lead: string;
  lineItems: InvoiceLineItem[];
  submissionDate: Date | string;
  notes: InvoiceNote[];

  constructor({
    id,
    compoundId,
    invoiceId,
    version,
    state,
    contractor,
    event,
    client,
    division,
    position,
    lead,
    lineItems,
    submissionDate,
    notes
  }: IInvoice) {
    this.id = id;
    this.compoundId = compoundId;
    this.invoiceId = invoiceId;
    this.version = version;
    this.state = state;
    this.contractor = contractor;
    this.event = event;
    this.client = client;
    this.division = division;
    this.position = position;
    this.lead = lead;
    this.lineItems = lineItems.map((jsonObj) => {
      return new InvoiceLineItem({ ...jsonObj });
    });
    this.submissionDate = submissionDate;
    this.notes = notes.map((jsonObj) => {
      return new InvoiceNote({ ...jsonObj });
    });
  }

  getLineItems(): InvoiceLineItem[] {
    return this.lineItems.slice().sort((itemA, itemB) => {
      const typeComparison = INVOICE_LINE_ITEM_TYPE_ORDER.indexOf(itemA.type) - INVOICE_LINE_ITEM_TYPE_ORDER.indexOf(itemB.type);
      if (typeComparison === 0) {
        return INVOICE_LINE_ITEM_SUBTYPE_ORDER.indexOf(itemA.subtype) - INVOICE_LINE_ITEM_SUBTYPE_ORDER.indexOf(itemB.subtype);
      }
      return typeComparison;
    });
  }

  lineItemsBySubtype(subtype: InvoiceLineItemSubtype): InvoiceLineItem[] {
    return this.lineItems.filter((item) => item.subtype === subtype);
  }

  laborItems(): InvoiceLineItem[] {
    return this.lineItems.filter((item) => item.type === InvoiceLineItemType.LABOR);
  }

  laborSubtotal(): number {
    return this.laborItems().reduce((sum, item) => sum + item.total(), 0);
  }

  expenseItems(): InvoiceLineItem[] {
    return this.lineItems.filter((item) => item.type === InvoiceLineItemType.EXPENSE);
  }

  expenseSubtotal(): number {
    return this.expenseItems().reduce((sum, item) => sum + item.total(), 0);
  }

  total(): number {
    return this.lineItems.reduce((sum, item) => sum + item.total(), 0);
  }

  createEmptyLineItem(subtype: InvoiceLineItemSubtype): InvoiceLineItem {
    const type = SUBTYPE_TO_TYPE_MAP[subtype];
    const countOfSubtype = this.lineItems.filter((item) => item.subtype === subtype).length;
    const lineItemId = `${this.invoiceId}-${subtype}-${countOfSubtype + 1}`;
    const version = 1;
    const rate = SUBTYPES_WITH_VARIABLE_RATES.includes(subtype) ? 0 : 1;
    const lineItem = new InvoiceLineItem({
      id: '',
      lineItemId: lineItemId,
      version: version,
      type: type,
      subtype: subtype,
      qty: 0,
      rate: rate,
      desc: ''
    });
    this.lineItems.push(lineItem);
    return lineItem;
  }

  findLineItem(subtype: InvoiceLineItemSubtype, occurrence: number = 1): InvoiceLineItem | undefined {
    return this.lineItems.find(
      (item) => item.subtype === subtype && parseInt(item.lineItemId.charAt(item.lineItemId.length - 1)) === occurrence
    );
  }
  findOrCreateLineItem(subtype: InvoiceLineItemSubtype, occurrence: number = 1): InvoiceLineItem {
    return this.findLineItem(subtype, occurrence) ?? this.createEmptyLineItem(subtype);
  }
}
