import { NavItemType } from '../types/menu';
import { FormattedMessage } from 'react-intl';
import { SettingOutlined, LogoutOutlined } from '@ant-design/icons';

const icons = {
  SettingOutlined,
  LogoutOutlined
};

const settings: NavItemType = {
  id: 'settings-group',
  type: 'group',
  children: [
    {
      id: 'settings',
      title: <FormattedMessage id="settings" />,
      type: 'item',
      url: '/profile/settings',
      icon: icons.SettingOutlined
    },
    {
      id: 'logout',
      title: <FormattedMessage id="logout" />,
      type: 'item',
      url: '/logout',
      icon: icons.LogoutOutlined
    }
  ]
};

export default settings;
