import React from 'react';
import logo from 'assets/images/logo.png';

// ==============================|| LOGO SVG ||============================== //
interface LogoProps {
  reverse?: boolean;
  style?: React.CSSProperties;
}

const LogoMain = ({ reverse, style, ...others }: LogoProps) => {
  return <img src={logo} alt="OSR360" style={{ width: 100, ...style }} />;
};

export default LogoMain;
