import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RoleGuard from '../utils/route-guard/RoleGuard';

// pages routing
const AuthLogout = Loadable(lazy(() => import('pages/auth/logout')));
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// users
const UsersPage = Loadable(lazy(() => import('pages/UsersPage/UsersPage')));
const UserProfile = Loadable(lazy(() => import('pages/profiles/UserProfile')));
const UserTabPersonal = Loadable(lazy(() => import('sections/profiles/TabPersonal')));
const UserTabPassword = Loadable(lazy(() => import('sections/profiles/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('sections/profiles/TabSettings')));

// invoices
const InvoiceList = Loadable(lazy(() => import('pages/invoices/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('pages/invoices/InvoiceDetails')));
const InvoiceWizardPage = Loadable(lazy(() => import('pages/invoices/InvoiceWizardPage')));
const InvoiceEdit = Loadable(lazy(() => import('pages/invoices/InvoiceEdit')));

// events
const EventCardsPage = Loadable(lazy(() => import('pages/events/EventCardsPage')));
const UserAssignmentPage = Loadable(lazy(() => import('pages/events/UserAssignment')));
const EventInvoicesPage = Loadable(lazy(() => import('pages/events/EventInvoices')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'logout',
          element: <AuthLogout />
        },
        {
          path: 'sample-page',
          element: <SamplePage />
        },
        {
          path: 'events',
          children: [
            {
              path: '',
              element: <EventCardsPage />
            },
            {
              path: ':id',
              children: [
                {
                  path: 'personnel',
                  element: (
                    <RoleGuard>
                      <UserAssignmentPage />
                    </RoleGuard>
                  )
                },
                {
                  path: 'invoices',
                  element: <EventInvoicesPage />
                }
              ]
            }
          ]
        },
        {
          path: 'profile',
          element: <UserProfile />,
          children: [
            {
              path: 'personal',
              element: <UserTabPersonal />
            },
            {
              path: 'password',
              element: <UserTabPassword />
            },
            {
              path: 'settings',
              element: <UserTabSettings />
            }
          ]
        },
        {
          path: 'invoices',
          children: [
            {
              path: '',
              element: <InvoiceList />
            },
            {
              path: ':id',
              children: [
                {
                  path: '',
                  element: <InvoiceDetails />
                },
                {
                  path: 'wizard',
                  element: <InvoiceWizardPage />
                },
                {
                  path: 'edit',
                  element: <InvoiceEdit />
                }
              ]
            }
          ]
        },
        {
          path: 'admin/users',
          element: (
            <RoleGuard>
              <UsersPage />
            </RoleGuard>
          )
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
