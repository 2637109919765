import React, { createContext, useEffect, useReducer } from 'react';

// third-party
// import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { KeyedObject } from 'types/root';
import { AuthProps, JWTContextType } from 'types/auth';

// const chance = new Chance();

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const response = await axios.get(`/users/profile`);
          const { item } = response.data.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: item
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (username: string, password: string) => {
    await axios
      .post('/login', { username, password })
      .then((response) => {
        const { access_token, user } = response.data;
        setSession(access_token);
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user
          }
        });
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else if (error.status_code && error.success) {
          //API responded with expected json structure
          console.log(error.message);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          error.message = 'Login server is currently unavailable';
        }
        throw error;
      });
  };

  const register = async (userId: string, username: string, password: string, confirmPassword: string, token: string) => {
    // todo: this flow need to be recode as it not verified
    // const id = chance.bb_pin();
    await axios.post(
      `/register/${userId}`,
      {
        username: username,
        password: password,
        confirm_password: confirmPassword
      },
      {
        params: {
          t: token
        }
      }
    );
    // let users = response.data;

    // if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
    //   const localUsers = window.localStorage.getItem('users');
    //   users = [
    //     ...JSON.parse(localUsers!),
    //     {
    //       id,
    //       email,
    //       password,
    //       name: `${firstName} ${lastName}`
    //     }
    //   ];
    // }
    //
    // window.localStorage.setItem('users', JSON.stringify(users));
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const forgotPassword = async (email: string) => {
    await axios.post('/reset-password/send', {
      email: email
    });
  };
  const resetPassword = async (token: string, password: string, confirmPassword: string) => {
    await axios.post(
      '/reset-password',
      {
        password: password,
        confirm_password: confirmPassword
      },
      {
        params: {
          t: token
        }
      }
    );
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, register, forgotPassword, resetPassword }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
