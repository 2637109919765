import { Invoice } from './invoice';

export interface UserProps {
  users: User[];
  user: User | null;
  error: object | string | null;
}

export interface IUser {
  id: string;
  username: string;
  empId: string;
  name: string;
  firstName: string;
  lastName: string;
  middleName: string;
  alias: string;
  lead: string;
  phone: string;
  email: string;
  jobTitle: string;
  addressStreet: string;
  city: string;
  state: string;
  zipCode: string;
  status: string;
  role: string;
  profileUpdateRequest: string;
  registrationState: string;
  emailOptOut: boolean;
  generalEmailOptOut: boolean;
  invoiceRequestOptOut: boolean;
  invoiceCopyOptOut: boolean;
  invoices: Invoice[];
}

export interface IEventUser {
  id: string;
  name: string;
  phone: string;
  email: string;
  clients: string[];
}

export class EventUser implements IEventUser {
  id: string;
  name: string;
  phone: string;
  email: string;
  clients: string[];

  constructor(iEventUser: IEventUser) {
    this.id = iEventUser.id;
    this.name = iEventUser.name;
    this.phone = iEventUser.phone;
    this.email = iEventUser.email;
    this.clients = iEventUser.clients;
  }
}

export class User implements IUser {
  id: string;
  username: string;
  empId: string;
  name: string;
  firstName: string;
  lastName: string;
  middleName: string;
  alias: string;
  lead: string;
  phone: string;
  email: string;
  jobTitle: string;
  addressStreet: string;
  city: string;
  state: string;
  zipCode: string;
  status: string;
  role: string;
  profileUpdateRequest: string;
  registrationState: string;
  emailOptOut: boolean;
  generalEmailOptOut: boolean;
  invoiceRequestOptOut: boolean;
  invoiceCopyOptOut: boolean;
  invoices: Invoice[];

  constructor(iUser: IUser) {
    this.id = iUser.id;
    this.username = iUser.username;
    this.empId = iUser.empId;
    this.name = iUser.name;
    this.firstName = iUser.firstName;
    this.lastName = iUser.lastName;
    this.middleName = iUser.middleName;
    this.alias = iUser.alias;
    this.lead = iUser.lead;
    this.phone = iUser.phone;
    this.email = iUser.email;
    this.jobTitle = iUser.jobTitle;
    this.addressStreet = iUser.addressStreet;
    this.city = iUser.city;
    this.state = iUser.state;
    this.zipCode = iUser.zipCode;
    this.status = iUser.status;
    this.role = iUser.role;
    this.profileUpdateRequest = iUser.profileUpdateRequest;
    this.registrationState = iUser.registrationState;
    this.emailOptOut = iUser.emailOptOut;
    this.generalEmailOptOut = iUser.generalEmailOptOut;
    this.invoiceRequestOptOut = iUser.invoiceRequestOptOut;
    this.invoiceCopyOptOut = iUser.invoiceCopyOptOut;
    this.invoices = iUser.invoices;
  }
}
