// project import
import axios from 'utils/axios';
import { dispatch } from 'store';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// types
import { Invoice, InvoiceProps } from 'types/invoice';

const initialState: InvoiceProps = {
  invoices: [],
  invoice: null,
  error: null,
  alertPopup: false
};

// ==============================|| INVOICE - SLICE ||============================== //

const invoice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    // review invoice popup
    // reviewInvoicePopup(state, action) {
    //   state.isOpen = action.payload.isOpen;
    // },

    // is customer open
    // customerPopup(state, action) {
    //   state.isCustomerOpen = action.payload.isCustomerOpen;
    // },

    // handler customer form popup
    // toggleCustomerPopup(state, action) {
    //   state.open = action.payload.open;
    // },

    // handler customer form popup
    // selectCountry(state, action) {
    //   state.country = action.payload.country;
    // },

    hasError(state, action) {
      state.error = action.payload.error;
    },

    // get all invoices
    getInvoices(state, action) {
      state.invoices = action.payload.map((jsonObj: any) => {
        return new Invoice({ ...jsonObj });
      });
    },

    // get invoice details
    getInvoice(state, action) {
      state.invoice = new Invoice({ ...action.payload });
    },

    // create invoice
    createInvoice(state, action) {
      state.invoices = [...state.invoices, action.payload];
    },

    // update invoice
    updateInvoice(state, action) {
      const newInvoice = new Invoice({ ...action.payload });
      state.invoices = state.invoices.map((item) => {
        if (item.id === newInvoice.id) {
          return newInvoice;
        }
        return item;
      });
      state.invoice = newInvoice;
    },

    // delete invoice
    deleteInvoice(state, action) {
      const { invoiceId } = action.payload;
      state.invoices = state.invoices.filter((list) => list.id !== invoiceId);
    },

    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

export default invoice.reducer;

export const { getInvoices, alertPopupToggle } = invoice.actions;

export function getInvoiceList() {
  return async () => {
    try {
      const response = await axios.get('/invoices');
      dispatch(invoice.actions.getInvoices(response.data.data.items));
    } catch (error) {
      dispatch(invoice.actions.hasError(error));
    }
  };
}

// export function getInvoiceInsert(NewLists: Invoice) {
//   return async () => {
//     try {
//       const response = await axios.post('/api/invoice/insert', { list: NewLists });
//       dispatch(invoice.actions.createInvoice(response.data));
//     } catch (error) {
//       dispatch(invoice.actions.hasError(error));
//     }
//   };
// }
//
// export function getInvoiceUpdate(NewLists: Invoice) {
//   return async () => {
//     try {
//       const response = await axios.post('/api/invoice/update', NewLists);
//       dispatch(invoice.actions.UpdateInvoice(response.data));
//     } catch (error) {
//       dispatch(invoice.actions.hasError(error));
//     }
//   };
// }
//
// export function getInvoiceDelete(invoiceId: number) {
//   return async () => {
//     try {
//       await axios.post('/api/invoice/delete', { invoiceId });
//       dispatch(invoice.actions.deleteInvoice({ invoiceId }));
//     } catch (error) {
//       dispatch(invoice.actions.hasError(error));
//     }
//   };
// }
//
export function getInvoice(invoiceId: any) {
  return async () => {
    try {
      const response = await axios.get(`/invoices/${invoiceId}`);
      dispatch(invoice.actions.getInvoice(response.data.data.item));
    } catch (error) {
      dispatch(invoice.actions.hasError(error));
    }
  };
}

export function updateInvoice(invoiceData: any) {
  dispatch(invoice.actions.updateInvoice(invoiceData));
}
