import React from 'react';
import logo from 'assets/images/logo-icon.png';

// ==============================|| LOGO ICON SVG ||============================== //

interface LogoIconProps {
  style?: React.CSSProperties;
}

const LogoIcon = ({ style }: LogoIconProps) => {
  return <img src={logo} alt="OSR360" style={{ width: '50px', ...style }} />;
};

export default LogoIcon;
